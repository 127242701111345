import React, { useRef } from 'react';
import Layout from 'layout';
import styled from 'styled-components';
import { ReactTypeformEmbed } from 'react-typeform-embed';
import { media } from 'theme/breakpoints';
import Button from 'components/ui/button';
import hero from 'img/affiliate-program/hero.svg';
import FAQ from 'components/FAQ';
import TrustedBySmall from 'components/ui/trustedBySmall';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import { questions } from 'data/affiliate-program/questions';
import earn from 'img/affiliate-program/icons/earn.svg';
import promote from 'img/affiliate-program/icons/promote.svg';
import join from 'img/affiliate-program/icons/join.svg';
import bsLogo from 'img/affiliate-program/bs-logo.svg';
import ceo from 'img/affiliate-program/ceo.png';
import cover from 'img/affiliate-program/cover.png';

const Header = styled.header`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 80px;
  align-items: center;
  margin-top: 9rem;
  margin-bottom: 80px;
  & .subtitle {
    margin-bottom: 40px;
  }
  & h1.line {
    & span {
      display: inline-block;
    }
  }
  ${media.desktop} {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    & img {
      margin: 0 auto;
    }
    & div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      & h1.line {
        & span {
          display: inline;
        }
      }
    }
  }
  ${media.tablet} {
    & div {
      & h1.line {
        & span {
          display: inline-block;
        }
      }
    }
  }
  ${media.mobile} {
    margin-top: 6rem;
    grid-gap: 56px;
    margin-bottom: 60px;
  }
`;

const Banner = styled.section`
  background-color: #f8f8f8;
  border-radius: 4px;
  padding: 56px 64px;
  margin: 32px 0 0;
  & p {
    margin: 0;
    font-size: 22px;
    line-height: 34px;
    text-align: center;
  }
  ${media.desktop} {
    margin: 70px 0 0;
    padding: 32px 40px;
    & p {
      font-size: 20px;
      line-height: 34px;
    }
  }
  ${media.mobile} {
    padding: 32px 16px;
    margin: 60px 0 0;
    & p {
      font-size: 18px;
      line-height: 30px;
    }
  }
`;

const CTA = styled.section`
  background-color: #7dffef;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 72px 0;
  margin-bottom: 144px;
  margin-top: 60px;
  border-radius: 4px;
  & h2 {
    margin-bottom: 24px;
  }
  & p {
    margin-bottom: 40px;
  }
  ${media.desktop} {
    margin-top: 0;
  }
  ${media.mobile} {
    padding: 32px 24px;
    & h2 {
      font-size: 26px;
    }
    & p {
      text-align: center;
      margin-bottom: 32px;
    }
  }
`;

const HowItWorks = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 42px 0;
  & h2 {
    margin-bottom: 90px;
  }
  ${media.tablet} {
    & h2 {
      margin-bottom: 56px;
    }
  }
  ${media.mobile} {
    margin: 0;
    & h2 {
      font-size: 26px;
    }
  }
`;

const BoxContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 56px;
  margin-bottom: 64px;
  ${media.desktop} {
    grid-gap: 32px;
  }
  ${media.tablet} {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, auto);
    grid-gap: 56px;
    margin-bottom: 64px;
  }
  & div {
    ${media.tablet} {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 80%;
      margin: 0 auto;
    }
    & img {
      max-width: 48px;
      margin-bottom: 16px;
    }
    & h3 {
      margin-bottom: 16px;
      font-size: 24px;
      line-height: 34px;
      ${media.mobile} {
        font-size: 22px;
        line-height: 32px;
      }
    }
    & p {
      margin: 0;
      font-size: 16px;
      line-height: 26px;
    }
  }
`;

const QuoteSection = styled.section`
  background-color: #f8f8f8;
  padding: 94px 0 81px;
  margin-bottom: 132px;
  ${media.mobile} {
    padding: 40px 16px;
    margin-bottom: 90px;
  }
`;

const Quote = styled.div`
  display: grid;
  grid-template-columns: auto 340px;
  grid-gap: 86px;
  & div {
    & p:first-child {
      font-size: 32px;
      line-height: 50px;
      margin-bottom: 24px;
    }
    & p.name {
      font-size: 20px;
      line-height: 34px;
      margin-bottom: 8px;
    }
    & img {
      max-width: 128px;
    }
  }
  ${media.desktop} {
    grid-gap: 40px;
    grid-template-columns: auto 200px;
    & div {
      & p:first-child {
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 24px;
      }
      & p.name {
        font-size: 18px;
        line-height: 30px;
        margin-bottom: 8px;
      }
    }
  }
  ${media.tablet} {
    grid-template-columns: auto;
    & .ceo {
      display: none;
    }
  }
  ${media.mobile} {
    & div {
      & p:first-child {
        font-size: 20px;
        line-height: 34px;
      }
    }
  }
`;

const AffiliateProgram = () => {
  const form = useRef();

  const handleOpenForm = () => {
    form.current.typeform.open();
  };

  const handleDownloadKitClicks = () => {
    trackCustomEvent({
      category: 'Affiliate program',
      action: 'Click',
      label: 'Download promo materials',
    });
  };

  return (
    <Layout
      canonical="/partners/"
      metaTitle="Affiliate Program"
      metaDescription="Earn money with the LiveSession Affiliate Program. Get 20% recurring commission."
      image={cover}
    >
      <div className="container">
        <Header>
          <div>
            <h1 className="line">
              LiveSession <span>Affiliate Program</span>
            </h1>
            <p className="subtitle">Get a 20% commission for every paying customer.</p>
            <Button as="button" onClick={() => handleOpenForm()}>
              Get started
            </Button>
          </div>
          <img
            src={hero}
            alt="hero"
            title="Get started with our affiliate program!"
            className="img-fluid"
          />
        </Header>
        <TrustedBySmall />
        <Banner>
          <p>
            As a LiveSession affiliate partner, you’ll get a <strong>20%</strong> recurring
            commission for every user you refer through an affiliate link. For example, if the user
            pays $99 monthly, you’ll get <strong>$19.8 each month</strong>.
          </p>
        </Banner>
        <HowItWorks>
          <h2>How it works</h2>
          <BoxContainer>
            <div>
              <img src={join} alt="Join - icon" title="Join the program" />
              <h3>Join the program</h3>
              <p>Once you’re in, you’ll get a dedicated affiliate link just for you.</p>
            </div>
            <div>
              <img src={promote} alt="Promote - icon" title="Promote LiveSession" />
              <h3>Promote LiveSession</h3>
              <p>
                Share the link with your community and spread the word about LiveSession. Download
                our{' '}
                <a href="/affiliates.zip" onClick={handleDownloadKitClicks}>
                  promo materials
                </a>
                .
              </p>
            </div>
            <div>
              <img src={earn} alt="Earn money - icon" title="Earn money" />
              <h3>Earn money</h3>
              <p>
                You’ll get a 20% commission for every payment made by a user who joined via your
                affiliate link.
              </p>
            </div>
          </BoxContainer>
          <Button as="button" onClick={() => handleOpenForm()}>
            Become an affiliate
          </Button>
        </HowItWorks>
      </div>
      <QuoteSection>
        <div className="container">
          <Quote>
            <div>
              <p>
                “The program <strong>rules are clear</strong> and there is{' '}
                <strong>no fine print</strong>. LiveSession is a competitive offer for everyone
                dealing with qualitative analytics. Gotta admit it&apos;s{' '}
                <strong>practically selling itself</strong>!”
              </p>
              <p className="name">
                <strong>Thomas Cheng</strong>, CEO
              </p>
              <img src={bsLogo} alt="BeamStyle - logo" title="BeamStyle" />
            </div>
            <img
              src={ceo}
              alt="CEO"
              title="Thomas Cheng - CEO of BeamStyle"
              className="img-fluid ceo"
            />
          </Quote>
        </div>
      </QuoteSection>
      <div className="container">
        <FAQ questions={questions} />
        <CTA>
          <h2>Join the program</h2>
          <p>Get a 20% commission for every paying customer.</p>
          <Button as="button" onClick={() => handleOpenForm()}>
            Become an affiliate
          </Button>
        </CTA>
      </div>
      <ReactTypeformEmbed
        url="https://hello811074.typeform.com/to/A4gNh79V"
        autoOpen={false}
        ref={form}
        popup
        hideHeaders
        hideFooter
        style={{ top: '-100%' }}
      />
    </Layout>
  );
};

export default AffiliateProgram;
