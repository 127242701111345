import React from 'react';

export const questions = [
  {
    question: 'How does the commission work? ',
    answer: (
      <>
        You’re getting a <strong>20%</strong> commission from every payment from a client that has
        registered with your affiliate link.
      </>
    ),
  },
  {
    question: 'Do I need to issue an invoice?',
    answer: 'You need to issue an invoice to LiveSession, e.g. through PayPal.',
  },
  {
    question: 'Is there a minimum amount I can withdraw?',
    answer: (
      <>
        You can withdraw more than <strong>$50</strong> through PayPal.
      </>
    ),
  },
  {
    question: 'Can I withdraw the funds right after the client has paid?',
    answer: 'You can withdraw your funds 30 days after the client has paid.',
  },
  {
    question: 'How does the affiliate link work?',
    answer: (
      <>
        Every partner gets a unique partner ID. When the user registers with a partner link{' '}
        <strong>{`(livesession.io?a={partneriD})`}</strong>, their account is assigned to the
        partner.
      </>
    ),
  },
  {
    question: 'Does the customer have to register immediately?',
    answer: (
      <>
        The client doesn’t have to register right away. After landing on the LiveSesssion page
        through the affiliate link, a cookie with a partner ID is added for <strong>90 days</strong>
        . This means that if the client clicks on an affiliate link and registers within{' '}
        <strong>90 days</strong>, the account still counts as a part of the program. The only
        exception is when the user clicks on another affiliate link that would overwrite the
        previous one.{' '}
      </>
    ),
  },
  {
    question: 'Can I get any materials to promote LiveSession?',
    answer: (
      <>
        Yes! We’ve prepared a promo materials that you can{' '}
        <a href="/affiliates.zip">download here</a>. The package includes our logo in different
        formats and display ads banners. You can use them to promote LiveSession.
      </>
    ),
  },
];
