import React from 'react';
import bsLogo from 'img/affiliate-program/bs-logo.svg';
import fmLogo from 'img/companyLogo/fm.svg';
import tgaLogo from 'img/companyLogo/thegrowthacademy.svg';
import * as Styled from './styled';

const TrustedBySmall = () => {
  return (
    <Styled.Section>
      <div>
        <p>Trusted by</p>
      </div>
      <div>
        <img src={bsLogo} alt="logo" title="BeamStyle" className="img-fluid" />
      </div>
      <div>
        <img src={fmLogo} alt="logo" title="FM" className="img-fluid" />
      </div>
      <div>
        <img src={tgaLogo} alt="logo" title="The Growth Academy" className="img-fluid" />
      </div>
    </Styled.Section>
  );
};

export default TrustedBySmall;
